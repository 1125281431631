import { useEffect } from 'react'
import { navigate } from 'gatsby'

export default ({ location }: { location: any }) => {
    useEffect(() => {
        let redirectPath, redirectOptions

        if (location.pathname.startsWith('/occasions/')) {
            redirectPath = '/occasions/'
            redirectOptions = { state: { from404Redirect: true } }
        } else if (location.pathname.startsWith('/inventories/')) {
            redirectPath = '/inventories/'
            redirectOptions = { state: { from404Redirect: true } }
        } else {
            redirectPath = '/'
            redirectOptions = {}
        }

        navigate(redirectPath, redirectOptions)
    }, [])

    return null
}
